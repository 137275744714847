import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const Wrapper = styled.div`
  margin: 2rem 0;
`;

const Pages = ({ pages, activePage }) => {
  return (
    <Wrapper>
      <Pagination
        page={activePage}
        count={pages}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`/blog/${item.page === 1 ? '' : item.page}`}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#1f5dea',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0f44bb',
                },
              },
            }}
            {...item}
          />
        )}
      />
    </Wrapper>
  );
};

export default Pages;
