import ArticleCard, { ArticleCardProps } from '../components/blog/ArticleCard';
import CTA, { CTAProps } from '../components/blog/CTA';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { BlogPageProps } from '../utils/blog/types';
import Layout from '../components/layout';
import NotFoundPage from '../pages/404';
import SEO from '../components/seo';
import { countWords } from '../utils/blog';
import { device } from '../utils/blog/breakpoints';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Pages from '../components/blog/Pagination';

const Grid = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 64px 32px;
  grid-template-areas: '.';
  > * {
    display: flex;
  }
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .';
    > *:nth-child(n + 10) {
      display: flex;
    }
  }
  @media ${device.lg} {
    grid-template-areas: '. . .';
    grid-template-columns: 1fr 1fr 1fr;
    > *:nth-child(n + 10) {
      display: none;
    }
  }
`;

const Hero = styled.div`
  margin-top: 56px;
  padding: 80px 20px 70px;
  @media ${device.md} {
    padding: 124px 60px 100px;
  }
  background-color: #f6f9fe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }
  h1 {
    font-size: 32px;
    line-height: 133%;
    @media ${device.md} {
      font-size: 48px;
      line-height: 133%;
    }
    color: #253858;
  }
  h2 {
    font-size: 16px;
    line-height: 133%;
    @media ${device.md} {
      font-size: 18px;
      line-height: 133%;
    }
    color: #5c6370;
  }
`;

const BlogFrontpage = ({
  pageContext,
  data: {
    allPrismicBlogArticle: { edges: articles },
    allPrismicBlogFrontpage: data,
  },
}: BlogPageProps) => {
  const { language } = pageContext;
  useIntl();
  const pageData = data.edges[0]?.node?.data || null;
  const [cta, setCta] = useState<CTAProps | undefined>();

  useEffect(() => {
    if (!pageData) return;
    if (pageData.cta_title) {
      setCta({
        cta_title: pageData.cta_title,
        cta_description: pageData.cta_description,
        cta_email_field_placeholder: pageData.cta_email_field_placeholder,
        cta_button_label: pageData.cta_button_label,
        form_id: 'f324e048-58a8-4d48-80cd-67fbf899bc19',
      });
    }
  }, []);

  if (!pageData)
    return (
      <NotFoundPage
        pageContext={pageContext}
        navigate={(url) => {
          return (window.location = url);
        }}
      />
    );

  return (
    <div>
      <Layout lang={language}>
        <SEO
          title={pageData.seo_page_title || pageData.hero_title || 'Blog'}
          description={
            pageData.seo_page_description || pageData.hero_subtitle || null
          }
          lang={language}
        />
        <Hero>
          <h1>{pageData.hero_title}</h1>
          <h2>{pageData.hero_subtitle}</h2>
        </Hero>
        <div className="m-section__wrap">
          <Grid>
            {articles.map(
              ({
                node: {
                  last_publication_date,
                  tags,
                  id,
                  uid,
                  data: { cover_image, hero_title, excerpt, body, author },
                },
              }) => {
                const props: ArticleCardProps = {
                  cover_image,
                  hero_title,
                  word_count: countWords(
                    body.filter((val) => val.slice_type === 'paragraph')
                  ),
                  excerpt,
                  tags,
                  link: `/blog/${uid}`,
                  author,
                  last_published: last_publication_date,
                };
                return <ArticleCard {...props} key={id} />;
              }
            )}
          </Grid>
          <Pages
            pages={pageContext.numberOfPages}
            activePage={pageContext.humanPageNumber}
          />
        </div>
        {cta && <CTA {...cta} />}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query allPrismicBlogFrontpageAnd(
    $prismicLang: String
    $skip: Int
    $limit: Int
  ) {
    allPrismicBlogArticle(
      skip: $skip
      limit: $limit
      filter: { lang: { eq: $prismicLang } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          _previewable
          last_publication_date(formatString: "DD.MM.YYYY")
          tags
          id
          prismicId
          uid
          data {
            hero_title
            excerpt
            cover_image {
              alt
              url
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
            author {
              document {
                ... on PrismicBlogAuthor {
                  id
                  data {
                    full_name
                    profile_photo {
                      alt
                      url
                    }
                  }
                }
              }
              target
            }
            body {
              ... on PrismicBlogArticleDataBodyParagraph {
                slice_type
                items {
                  title
                  text {
                    raw
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicBlogFrontpage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          id
          lang
          data {
            hero_subtitle
            hero_title
            seo_page_description
            seo_page_title
            cta_title
            cta_description
            cta_button_label
            cta_email_field_placeholder
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(BlogFrontpage, [
  {
    repositoryName: 'deeptrue-blog',
  },
]);
